import { Checkbox as ElmoCheckbox, FormItem } from 'elmo-elements';
import { useField } from 'formik';
import React from 'react';

const Checkbox = ({ label, id, onChange, ...props }: any) => {
  const [field, meta, helpers] = useField(props);
  const isError = meta.touched && meta.error;

  const handleChange = (e: any) => {
    helpers.setValue(e.target.checked);

    if (onChange) {
      onChange(e.target.checked);
    }
  };

  return (
    <FormItem
      message={isError ? meta.error : undefined}
      status={isError ? 'error' : 'default'}
    >
      <ElmoCheckbox
        {...props}
        isChecked={field.value}
        id={id}
        onChange={handleChange}
        label={label}
      />
    </FormItem>
  );
};

export default Checkbox;
