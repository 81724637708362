import { RouteObject } from 'react-router-dom';
import React from 'react';

import SurveyTemplateListPage from './pages/SurveyTemplatesList';

const Routes: RouteObject = {
  path: '/templates',
  children: [
    {
      path: '/templates/active',
      element: <SurveyTemplateListPage />,
    },
    {
      path: '/templates/archived',
      element: <SurveyTemplateListPage />,
    },
  ],
};

export default Routes;
