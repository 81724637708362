export const hasPropertyValue = (
  element: any,
  property: string,
  value: string
): boolean => {
  return element.hasOwnProperty(property) && element[property] === value;
};

export const getEmailInputQuestions = (structure: any): any[] => {
  let questionTypes = new Set();

  if (structure && structure.pages) {
    for (const page of structure.pages) {
      if (page.elements) {
        for (const element of page.elements) {
          if (
            hasPropertyValue(element, 'type', 'text') &&
            hasPropertyValue(element, 'inputType', 'email')
          ) {
            questionTypes.add(element);
          }
        }
      }
    }
  }

  return Array.from(questionTypes);
};

export const getChoiceQuestions = (structure: any): any[] => {
  let questionTypes = new Set();

  if (structure && structure.pages) {
    for (const page of structure.pages) {
      if (page.elements) {
        for (const element of page.elements) {
          if (
            hasPropertyValue(element, 'type', 'checkbox') ||
            hasPropertyValue(element, 'type', 'radiogroup') ||
            hasPropertyValue(element, 'type', 'dropdown') ||
            hasPropertyValue(element, 'type', 'rating') ||
            hasPropertyValue(element, 'type', 'boolean')
          ) {
            questionTypes.add(element);
          }
        }
      }
    }
  }

  return Array.from(questionTypes);
};

export const getQuestionById = (structure: any, questionId: string): any => {
  if (structure && structure.pages) {
    for (const page of structure.pages) {
      if (page.elements) {
        for (const element of page.elements) {
          if (hasPropertyValue(element, 'name', questionId)) {
            return element;
          }
        }
      }
    }
  }

  return null;
};

export const getChoicesForQuestion = (question: any) => {
  switch (question.type) {
    case 'rating':
      return question.rateValues;
    case 'boolean':
      return [
        { text: 'False', value: 0 },
        { text: 'True', value: 1 },
      ];
    case 'matrix':
    case 'matrixdropdown':
      return [];
    default:
      return question.choices;
  }
};
