/// <reference path= "../../../node_modules/react-froala-wysiwyg/lib/index.d.ts" />
import FroalaEditor from 'react-froala-wysiwyg';
import React from 'react';

import { registerPlaceholderCommand } from './helpers/placeholderCommand';
import { FROALA_KEY } from '../../lib/const';
import { StringMap } from 'type';

type EditorProps = {
  model?: string | object | null;
  onModelChange?: object;
  config?: object;
  enableImageUpload?: boolean;
  additionalPlaceholders?: StringMap<string>;
};

function Editor(props: EditorProps) {
  const {
    model,
    onModelChange,
    config,
    enableImageUpload,
    additionalPlaceholders,
  } = props;

  registerPlaceholderCommand(additionalPlaceholders);

  const textButtons = [
    'bold',
    'italic',
    'underline',
    'strikeThrough',
    'subscript',
    'superscript',
    'fontFamily',
    'fontSize',
    'textColor',
    'backgroundColor',
    'inlineClass',
    'inlineStyle',
    'clearFormatting',
  ];
  const paragraphButtons = [
    'alignLeft',
    'alignCenter',
    'alignRight',
    'alignJustify',
    'formatOL',
    'formatUL',
    'paragraphFormat',
    'paragraphStyle',
    'lineHeight',
    'outdent',
    'indent',
    'quote',
  ];
  const richButtons = [
    'insertLink',
    'insertTable',
    'emoticons',
    'specialCharacters',
    'insertHR',
    ...(enableImageUpload ? ['insertImage'] : []),
  ];
  const miscButtons = [
    'undo',
    'redo',
    'fullscreen',
    'selectAll',
    'html',
    'help',
  ];

  return (
    <FroalaEditor
      model={model}
      onModelChange={onModelChange}
      config={{
        key: FROALA_KEY,
        attribution: false,
        toolbarButtons: {
          moreText: {
            buttons: textButtons,
            buttonsVisible: 3,
          },
          moreParagraph: {
            buttons: paragraphButtons,
            buttonsVisible: 3,
          },
          moreRich: {
            buttons: richButtons,
            buttonsVisible: 3,
          },
          moreMisc: {
            buttons: miscButtons,
            align: 'right',
            buttonsVisible: 2,
          },
          placeHolder: {
            buttons: ['placeholders'],
          },
        },
        toolbarButtonsXS: {
          moreText: {
            buttons: textButtons,
            buttonsVisible: 2,
          },
          moreParagraph: {
            buttons: paragraphButtons,
            buttonsVisible: 2,
          },
          moreRich: {
            buttons: richButtons,
            buttonsVisible: 2,
          },
          placeHolder: {
            buttons: ['placeholders'],
          },
          moreMisc: {
            buttons: miscButtons,
            align: 'right',
            buttonsVisible: 2,
          },
        },
        quickInsertButtons: [
          ...(enableImageUpload ? ['image'] : []),
          'table',
          'ul',
          'ol',
          'hr',
        ],
        ...config,
        ...(enableImageUpload
          ? {
              imageUploadMethod: 'POST',
              imageMaxSize: 5 * 1024 * 1024,
              imageAllowedTypes: ['jpeg', 'jpg', 'png'],
            }
          : {}),
      }}
    />
  );
}

export default Editor;
