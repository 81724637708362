import { takeEvery } from 'redux-saga/effects';

import {
  deleteSurveyTemplate,
  getFeaturedTemplates,
  getSurveyTemplates,
  updateSurveyTemplate,
} from './actions';
import { getFeaturedTemplatesSaga } from './getFeaturedTemplates/sagas';
import { deleteSurveyTemplateSaga } from './deleteSurveyTemplate/sagas';
import { updateSurveyTemplateSaga } from './updateSurveyTemplate/sagas';
import { getSurveyTemplatesSaga } from './getSurveyTemplates/sagas';

export default function* sagas() {
  yield takeEvery(deleteSurveyTemplate, deleteSurveyTemplateSaga);
  yield takeEvery(getFeaturedTemplates, getFeaturedTemplatesSaga);
  yield takeEvery(getSurveyTemplates, getSurveyTemplatesSaga);
  yield takeEvery(updateSurveyTemplate, updateSurveyTemplateSaga);
}
