import { createRespondentProgressInitialState } from './createRespondentProgress/state';
import { updateRespondentProgressInitialState } from './updateRespondentProgress/state';
import { getSurveyCollectorUsageInitialState } from './getSurveyCollectorUsage/state';
import { updateSurveyAccessListInitialState } from './updateSurveyAccessList/state';
import { saveRespondentProgressInitialState } from './saveRespondentProgress/state';
import { getRespondentProgressInitialState } from './getRespondentProgress/state';
import { getSurveyAccessListInitialState } from './getSurveyAccessList/state';
import { getPreviewSurveyInitialState } from './getPreviewSurvey/state';
import { createRespondentInitialState } from './createRespondent/state';
import { deleteSurveyInitialState } from './deleteSurvey/state';
import { updateSurveyInitialState } from './updateSurvey/state';
import { cloneSurveyInitialState } from './cloneSurvey/state';
import { getSurveysInitialState } from './getSurveys/state';
import { getSurveyInitialState } from './getSurvey/state';
import { runSurveyInitialState } from './runSurvey/state';
import { SurveyState } from './type';

export const surveyInitialState: SurveyState = {
  getSurvey: getSurveyInitialState,
  getSurveys: getSurveysInitialState,
  cloneSurvey: cloneSurveyInitialState,
  getSurveyCollectorUsage: getSurveyCollectorUsageInitialState,
  deleteSurvey: deleteSurveyInitialState,
  updateSurvey: updateSurveyInitialState,
  getPreviewSurvey: getPreviewSurveyInitialState,
  getSurveyAccessList: getSurveyAccessListInitialState,
  updateSurveyAccessList: updateSurveyAccessListInitialState,
  runSurvey: runSurveyInitialState,
  createRespondent: createRespondentInitialState,
  createRespondentProgress: createRespondentProgressInitialState,
  updateRespondentProgress: updateRespondentProgressInitialState,
  getRespondentProgress: getRespondentProgressInitialState,
  saveRespondentProgress: saveRespondentProgressInitialState,
};
