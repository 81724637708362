import { getDecodedToken } from 'lib/token';
import featureFlag from 'lib/featureFlag';

export const isInviteCSVImportEnabled = () => {
  return featureFlag.isEnabled('feature-SV2-2273-invites-csv-import');
};

export const isRestoreQueryEnabled = () => {
  return featureFlag.isEnabled('feature-SV2-2346-restore-query');
};

export const isExistingSavedReportsEnabled = () => {
  return featureFlag.isEnabled('feature-SV2-2353-existing-saved-reports');
};

export const isSurveyPdfExportEnabled = () => {
  return featureFlag.isEnabled('feature-SV2-2366-survey-preview-pdf-export');
};

export const isQuestionSummariesPdfExportEnabled = () => {
  return featureFlag.isEnabled('feature-SV2-2366-survey-preview-pdf-export');
};

export const isDeleteResponseEnabled = () => {
  return featureFlag.isEnabled('feature-SV2-2368-delete-response');
};

export const isSurveyNotificationsEnabled = () => {
  return featureFlag.isEnabled('feature-SV2-2408-survey-notification');
};

export const isSurveyAccessEnabled = () => {
  return featureFlag.isEnabled('feature-SV2-2440-survey-access');
};

export const isELMOAdminEnabled = () => {
  return featureFlag.isEnabled('feature-SV2-2443-admin-area');
};

export const isPrintToPdfEnabled = () => {
  return featureFlag.isEnabled('feature-SV2-2456-print-to-pdf');
};

export const isPlaceholdersEnabled = () => {
  return featureFlag.isEnabled('feature-SV2-2461-placeholders-v1');
};

export const isBenchmarkEnabled = () => {
  const jwtObject = getDecodedToken();
  const benchmarkEnabled = !!(jwtObject?.industry_settings as any)
    ?.benchmarkEnabled;
  return (
    featureFlag.isEnabled('feature-SV2-1869-benchmark-report') &&
    benchmarkEnabled
  );
};

export const isNotificationsOnLogicEnabled = () => {
  return featureFlag.isEnabled('feature-SV2-2471-notification-on-logic');
};

export const isNotificationFilerValueEnabled = () => {
  return featureFlag.isEnabled('feature-SV2-2524-notification-filer-value');
};

export const isNotificationEmployeeProfileOptionsEnabled = () => {
  return featureFlag.isEnabled(
    'feature-SV2-2525-notification-employee-profile-options'
  );
};

export const isNotificationRecipientOptionsEnabled = () => {
  return featureFlag.isEnabled(
    'feature-SV2-2520-notification-recipient-options'
  );
};

export const isReopenCollectorEnabled = () => {
  return featureFlag.isEnabled('feature-SV2-1627-reopen-collector');
};

export const isScheduleCloseCollectorEnabled = () => {
  return featureFlag.isEnabled('feature-SV2-1627-schedule-close-collector');
};

export const isReportBreakdownEnabled = () => {
  return featureFlag.isEnabled('feature-SV2-2476-breakdown-by-filters');
};

export const isReactCreateThemePageEnabled = () => {
  return featureFlag.isEnabled('feature-SV2-2537-react-create-theme-page');
};

export const isQuestionCategoryPageEnabled = () => {
  return featureFlag.isEnabled(
    'feature-SV2-2533-react-create-question-category-page'
  );
};
