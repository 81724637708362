import { Navigate, RouteObject } from 'react-router-dom';
import React from 'react';

import SurveyNotificationRoutes from './section/surveyNotifications/routes';
import QuestionCategoriesRoutes from './section/questionCategories/routes';
import SurveyTemplatesRoutes from './section/surveyTemplates/routes';
import NotFound from './section/errorPages/pages/NotFound/NotFound';
import SurveyResponse from './section/reports/pages/SurveyResponse';
import UnderMaintenance from 'section/errorPages/pages/Maintenance';
import PrivateLayoutWithMenu from './layout/PrivateLayoutWithMenu';
import EmailTemplateRoutes from './section/emailTemplates/routes';
import EmailInviteRoutes from './section/emailInvites/routes';
import SharedReportRoutes from './section/shareReport/routes';
import PDFView from 'section/reports/pages/PDFView/PDFView';
import ErrorPagesRoutes from './section/errorPages/routes';
import SurveyFormRoutes from './section/surveyForm/routes';
import FilterLibraryRoutes from './section/filter/routes';
import HelpCentreRoutes from 'section/helpCentre/routes';
import Error from './section/errorPages/pages/Error';
import ReportListRoutes from './page/Reports/routes';
import ReportRoutes from './section/reports/routes';
import SurveyRoutes from './section/survey/routes';
import PrivateLayout from './layout/PrivateLayout';
import ThemeRoutes from './section/themes/routes';
import AdminRoutes from './section/admin/routes';
import SurveyLayout from './layout/SurveyLayout';
import RunSurvey from './section/runSurvey';

export const appRoutes: RouteObject[] = [
  {
    path: '/scheduled-maintenance',
    element: <UnderMaintenance />,
  },
  {
    path: '/report',
    element: <PrivateLayout />,
    children: SharedReportRoutes,
  },
  {
    path: '/run',
    element: <SurveyLayout />,
    children: [
      {
        path: '/run/:inviteHash',
        element: <RunSurvey />,
      },
    ],
  },
  {
    path: '/run-survey-learning',
    element: <SurveyLayout />,
    children: [
      {
        path: '/run-survey-learning/hash/:hash',
        element: <RunSurvey />,
      },
    ],
  },
  {
    path: '/run-survey-onboarding',
    element: <SurveyLayout />,
    children: [
      {
        path: '/run-survey-onboarding/hash/:hash',
        element: <RunSurvey />,
      },
    ],
  },
  {
    path: '/run-survey-recruitment',
    element: <SurveyLayout />,
    children: [
      {
        path: '/run-survey-recruitment/hash/:hash',
        element: <RunSurvey />,
      },
    ],
  },
  {
    path: '/run-survey',
    element: <SurveyLayout />,
    children: [
      {
        path: '/run-survey/:surveyId/:collectorHash',
        element: <RunSurvey />,
      },
    ],
  },
  {
    path: '/preview',
    element: <SurveyLayout />,
    children: [SurveyFormRoutes],
  },
  {
    path: '/',
    element: <PrivateLayout />,
    children: [
      {
        path: '/surveys/:surveyId/reports/question-summaries/report/:reportId/pdfExport',
        element: <PDFView />,
      },
      {
        path: '/surveys/:surveyId/response/:responseId',
        element: <SurveyResponse />,
      },
      {
        path: '/error/authError',
        element: <Error />,
      },
      {
        path: '/',
        element: <Navigate to={'/surveys/active'} />,
      },
      {
        path: '/',
        element: <PrivateLayoutWithMenu />,
        children: [
          EmailTemplateRoutes,
          HelpCentreRoutes,
          EmailInviteRoutes,
          ReportListRoutes,
          ReportRoutes,
          SurveyNotificationRoutes,
          SurveyRoutes,
          FilterLibraryRoutes,
          SurveyTemplatesRoutes,
          ThemeRoutes,
          QuestionCategoriesRoutes,
          AdminRoutes,
          ...ErrorPagesRoutes,
          {
            path: '*',
            element: <NotFound />,
          },
        ],
      },
    ],
  },
];
