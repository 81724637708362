import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { GetQuestionsQuery, GetQuestionsResponse } from './type';
import { ErrorResponse } from 'type';

export const getQuestions: EmptyActionCreator = createAction('GET_QUESTIONS');

export const getQuestionsSuccess: ActionCreator<GetQuestionsResponse> =
  createAction('GET_QUESTIONS_SUCCESS');

export const getQuestionsFailure: ActionCreator<ErrorResponse> = createAction(
  'GET_QUESTIONS_FAILURE'
);

export const updateGetQuestionsQuery: ActionCreator<GetQuestionsQuery> =
  createAction('UPDATE_GET_QUESTIONS_QUERY');

export const clearGetQuestions: EmptyActionCreator = createAction(
  'CLEAR_GET_QUESTIONS'
);
