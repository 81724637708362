import {
  GetQuestionsQuery,
  GetQuestionsResponse,
  QuestionSearchField,
} from './type';
import { Query } from 'lib/query/type';
import { getRequest } from 'lib/api';

const apiUrl = '/questions';

type GetQuestionsApiParams = {
  page: number;
  limit: number;
  'search-text'?: string;
  'search-field'?: string;
  'sort-field'?: string;
  'sort-type'?: 'asc' | 'desc';
};

type SortParams = Pick<GetQuestionsApiParams, 'sort-field' | 'sort-type'>;

export const createSortParams = (sort: Query['sort']): SortParams => {
  switch (sort) {
    case 'title':
    case '-title':
      return {
        'sort-field': 'title',
        'sort-type': sort === '-title' ? 'desc' : 'asc',
      };

    case 'type':
    case '-type':
      return {
        'sort-field': 'type',
        'sort-type': sort === '-type' ? 'desc' : 'asc',
      };

    default:
      return {};
  }
};

export const getQuestionsApi = (query: GetQuestionsQuery) => {
  const params: GetQuestionsApiParams = {
    page: query.page,
    limit: query.limit,
  };

  if (query.keyword) {
    params['search-text'] = query.keyword;
    params['search-field'] =
      query.searchField || QuestionSearchField.CATEGORY_TITLE;
  }

  return getRequest<GetQuestionsResponse>(apiUrl, {
    params: { ...params, ...createSortParams(query.sort) },
  });
};
