import { getFeaturedTemplatesInitialState } from './getFeaturedTemplates/state';
import { deleteSurveyTemplateInitialState } from './deleteSurveyTemplate/state';
import { getSurveyTemplatesInitialState } from './getSurveyTemplates/state';
import { updateSurveyInitialState } from '../survey/updateSurvey/state';
import { SurveyTemplateState } from './type';

export const surveyTemplateInitialState: SurveyTemplateState = {
  deleteSurveyTemplate: deleteSurveyTemplateInitialState,
  getFeaturedTemplates: getFeaturedTemplatesInitialState,
  getSurveyTemplates: getSurveyTemplatesInitialState,
  updateSurveyTemplate: updateSurveyInitialState,
};
