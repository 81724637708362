import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { getQuestionsFailure, getQuestionsSuccess } from './actions';
import { Query } from 'lib/stateTemplates';
import { GetQuestionsQuery } from './type';
import { getQuestionsApi } from './api';
import { getQuery } from 'lib/query';
import { ErrorResponse } from 'type';

export const getQuestionsSaga = function* (): SagaIterator {
  try {
    const query = getQuery(Query);
    const data = yield call(getQuestionsApi(query));
    yield put(getQuestionsSuccess(data));
  } catch (error) {
    yield put(getQuestionsFailure(error as ErrorResponse));
  }
};

export const updateGetQuestionsSaga = function* ({
  payload,
}: any): SagaIterator {
  try {
    const data = yield call(getQuestionsApi(payload as GetQuestionsQuery));
    yield put(getQuestionsSuccess(data));
  } catch (error) {
    yield put(getQuestionsFailure(error as ErrorResponse));
  }
};
