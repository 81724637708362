export function getMixpanelEnvironment(): string {
  return window.config.mixPanelEnv;
}

export function isHotJarEnabled(): boolean {
  return window.config.isHotJarEnabled;
}

export function hotJarSiteId(): string {
  return window.config.hotJarSiteId;
}

export function isPendoEnabled(): boolean {
  return window.config.isPendoEnabled;
}

export function pendoApiKey(): string {
  return window.config.pendoApiKey;
}
