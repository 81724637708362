import { takeEvery } from 'redux-saga/effects';

import {
  cloneSurvey,
  getSurvey,
  getSurveys,
  getSurveyCollectorUsage,
  deleteSurvey,
  updateSurvey,
  getPreviewSurvey,
  getSurveyAccessList,
  updateSurveyAccessList,
  runSurvey,
  createRespondent,
  createRespondentProgress,
  updateRespondentProgress,
  getRespondentProgress,
  saveRespondentProgress,
} from './actions';
import { createRespondentProgressSaga } from './createRespondentProgress/sagas';
import { updateRespondentProgressSaga } from './updateRespondentProgress/sagas';
import { getSurveyCollectorUsageSaga } from './getSurveyCollectorUsage/sagas';
import { updateSurveyAccessListSaga } from './updateSurveyAccessList/sagas';
import { saveRespondentProgressSaga } from './saveRespondentProgress/sagas';
import { getRespondentProgressSaga } from './getRespondentProgress/sagas';
import { getSurveyAccessListSaga } from './getSurveyAccessList/sagas';
import { getPreviewSurveySaga } from './getPreviewSurvey/sagas';
import { createRespondentSaga } from './createRespondent/sagas';
import { deleteSurveySaga } from './deleteSurvey/sagas';
import { updateSurveySaga } from './updateSurvey/sagas';
import { cloneSurveySaga } from './cloneSurvey/sagas';
import { getSurveysSaga } from './getSurveys/sagas';
import { getSurveySaga } from './getSurvey/sagas';
import { runSurveySaga } from './runSurvey/sagas';

export default function* sagas() {
  yield takeEvery(getSurvey, getSurveySaga);
  yield takeEvery(getSurveys, getSurveysSaga);
  yield takeEvery(cloneSurvey, cloneSurveySaga);
  yield takeEvery(getSurveyCollectorUsage, getSurveyCollectorUsageSaga);
  yield takeEvery(deleteSurvey, deleteSurveySaga);
  yield takeEvery(updateSurvey, updateSurveySaga);
  yield takeEvery(getPreviewSurvey, getPreviewSurveySaga);
  yield takeEvery(getSurveyAccessList, getSurveyAccessListSaga);
  yield takeEvery(updateSurveyAccessList, updateSurveyAccessListSaga);
  yield takeEvery(runSurvey, runSurveySaga);
  yield takeEvery(createRespondent, createRespondentSaga);
  yield takeEvery(createRespondentProgress, createRespondentProgressSaga);
  yield takeEvery(updateRespondentProgress, updateRespondentProgressSaga);
  yield takeEvery(getRespondentProgress, getRespondentProgressSaga);
  yield takeEvery(saveRespondentProgress, saveRespondentProgressSaga);
}
