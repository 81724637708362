import { select } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';

import {
  associatedFilterStateToQueryString,
  associatedFilterStateToSavedFiltersObject,
  collectorFilterStateToQueryString,
  collectorFilterStateToIdsArray,
} from 'lib/helpers/reports';
import { SelectedFiltersState } from 'type';
import { RootState } from 'store/type';

/**
 * Convert the selected filters to GET compatible query strings.
 */
export const getReportFilters = (state: RootState): any => {
  const filtersState: SelectedFiltersState =
    state.overviewReport.setOverviewReportFilters.filters;
  const filters: any = {};

  const collectorIds = collectorFilterStateToQueryString(
    filtersState.collectors
  );

  if (collectorIds !== '') {
    filters.collectorIds = collectorIds;
  }

  const associated = associatedFilterStateToQueryString(
    filtersState.associatedFilters
  );

  // if (associated !== '') {
  filters.associated = associated;
  // }

  filters.filterGenderFemale = filtersState.gender.filterGenderFemale;
  filters.filterGenderMale = filtersState.gender.filterGenderMale;
  filters.filterGenderOther = filtersState.gender.filterGenderOther;
  filters.ageGroups = JSON.stringify(filtersState.age);
  filters.residency = JSON.stringify(filtersState.residency);
  filters.pay = JSON.stringify(filtersState.pay);
  filters.employmentStatus = JSON.stringify(filtersState.employmentStatus);

  if (filtersState.position !== null) {
    filters.position = filtersState.position.value;
  }

  if (filtersState.manager !== null) {
    filters.manager = filtersState.manager.value;
  }

  if (filtersState.department !== null) {
    filters.department = filtersState.department.value;
  }

  if (filtersState.location !== null) {
    filters.location = filtersState.location.value;
  }

  filters.breakdownBy = filtersState.breakdown
    ? filtersState.breakdown.value
    : '';

  return filters;
};

/**
 * Convert the selected filters to GET compatible query strings.
 */
export const getReportFiltersForSavedOverviewReport = (
  state: RootState
): any => {
  const filtersState: SelectedFiltersState =
    state.overviewReport.setOverviewReportFilters.filters;
  const filters: any = {};

  filters.filterCollectors = collectorFilterStateToIdsArray(
    filtersState.collectors
  );

  filters.filterAssociated = associatedFilterStateToSavedFiltersObject(
    filtersState.associatedFilters
  );

  filters.filterGenderFemale = filtersState.gender.filterGenderFemale;
  filters.filterGenderMale = filtersState.gender.filterGenderMale;
  filters.filterGenderOther = filtersState.gender.filterGenderOther;
  filters.filterAge = filtersState.age;
  filters.filterResidency = filtersState.residency;
  filters.filterPay = filtersState.pay;
  filters.filterEmploymentStatus = filtersState.employmentStatus;

  filters.filterPosition = [];
  if (filtersState.position !== null) {
    filters.filterPosition.push(filtersState.position.value);
  }

  filters.filterManager = [];
  if (filtersState.manager !== null) {
    filters.filterManager.push(filtersState.manager.value);
  }

  filters.filterDepartment = [];
  if (filtersState.department !== null) {
    filters.filterDepartment.push(filtersState.department.value);
  }

  filters.filterLocation = [];
  if (filtersState.location !== null) {
    filters.filterLocation.push(filtersState.location.value);
  }

  return filters;
};

/**
 * Get and format the collectors from the saved report
 * so we can pass them to the setOverviewReportFilters action.
 */
export const getSavedCollectors = (state: RootState): any => {
  const collectors: any[] = state.overviewReport.getCollectorFilterOptions.data;
  const savedCollectors: number[] =
    state.overviewReport.getSavedOverviewReport.filters.filterCollectors;

  return collectors
    .filter((collector) => {
      return savedCollectors.includes(collector.id);
    })
    .map((collector) => {
      return {
        label: collector.title,
        value: collector.title,
        data: collector,
      };
    });
};

/**
 * Get and format the gender filter from the saved report
 * so we can pass it to the setOverviewReportFilters action.
 */
export const getSavedGender = (state: RootState): any => {
  const { filterGenderMale, filterGenderFemale, filterGenderOther } =
    state.overviewReport.getSavedOverviewReport.filters;

  return { filterGenderMale, filterGenderFemale, filterGenderOther };
};

/**
 * Get and format the checkbox style TMS filters from the saved report
 * so we can pass them to the setOverviewReportFilters action.
 */
export const getSavedTmsCheckboxFilters = (state: RootState): any => {
  return {
    age: state.overviewReport.getSavedOverviewReport.filters.filterAge,
    pay: state.overviewReport.getSavedOverviewReport.filters.filterPay,
    employmentStatus:
      state.overviewReport.getSavedOverviewReport.filters
        .filterEmploymentStatus,
    residency:
      state.overviewReport.getSavedOverviewReport.filters.filterResidency,
  };
};

/**
 * Get and format the associated filters from the saved report
 * so we can pass them to the setOverviewReportFilters action.
 */
export const getSavedAssociatedFilters = (state: RootState): any => {
  const associatedFilters =
    state.overviewReport.getOverviewReportFilterOptions
      .associatedFiltersOptions;
  const savedAssociatedFilters =
    state.overviewReport.getSavedOverviewReport.filters.filterAssociated;
  const results: any = {};

  Object.keys(savedAssociatedFilters).forEach((key) => {
    associatedFilters.forEach((filter: any) => {
      if (filter.id.toString() === key) {
        results[key] = filter.values
          .filter((filterValue: any) => {
            return savedAssociatedFilters[key].includes(filterValue.id);
          })
          .map((filterValue: any) => {
            return {
              label: filterValue.value,
              value: filterValue.value,
              data: filterValue,
            };
          });
      }
    });
  });

  return results;
};

/**
 * Get and format the dropdown style TMS filters from the saved report
 * so we can pass them to the setOverviewReportFilters action.
 */
export const getSavedTmsDropdownFilters = (state: RootState): any => {
  const filters = [
    {
      label: 'department',
      filterLabel: 'filterDepartment',
      filterOptionLabel: 'departmentOptions',
    },
    {
      label: 'position',
      filterLabel: 'filterPosition',
      filterOptionLabel: 'positionOptions',
    },
    {
      label: 'location',
      filterLabel: 'filterLocation',
      filterOptionLabel: 'locationOptions',
    },
    {
      label: 'manager',
      filterLabel: 'filterManager',
      filterOptionLabel: 'managerOptions',
    },
  ];

  const returns: any = {};

  filters.forEach((item) => {
    const allFilterOptions: any[] =
      // @ts-ignore
      state.overviewReport.getOverviewReportFilterOptions[
        item.filterOptionLabel
      ];
    const savedFilterOptions: any[] =
      state.overviewReport.getSavedOverviewReport.filters[item.filterLabel];

    if (isEmpty(savedFilterOptions)) {
      returns[item.label] = null;
    } else {
      returns[item.label] = allFilterOptions
        .filter((option) => {
          return savedFilterOptions.includes(option.filter);
        })
        .map((option) => {
          return {
            label: option.filter,
            value: option.filter,
          };
        });
    }
  });

  return returns;
};

/**
 * Get all the filters from the saved report state
 * and format them correctly so we can pass them along
 * to the setOverviewReportFilters state action.
 */
export const getSelectedFilters = function* () {
  const collectors = yield select(getSavedCollectors);
  const gender = yield select(getSavedGender);
  const { age, pay, employmentStatus, residency } = yield select(
    getSavedTmsCheckboxFilters
  );
  const { department, manager, location, position } = yield select(
    getSavedTmsDropdownFilters
  );
  const associatedFilters = yield select(getSavedAssociatedFilters);

  return {
    collectors,
    gender,
    age,
    pay,
    employmentStatus,
    residency,
    department,
    manager,
    location,
    position,
    associatedFilters,
  };
};
