import produce from 'immer';

import {
  getQuestions,
  getQuestionsFailure,
  getQuestionsSuccess,
  clearGetQuestions,
  updateGetQuestionsQuery,
} from './actions';
import { GetQuestionsQuery, GetQuestionsResponse } from './type';
import { transformQuestions } from '../../../transformers';
import { getQuestionsInitialState } from './state';
import { QuestionState } from '../type';

export function getQuestionsReducer(state: QuestionState): QuestionState {
  return produce(state, (draft: QuestionState) => {
    draft.getQuestions.isLoading = true;
    draft.getQuestions.isLoaded = false;
    draft.getQuestions.isValid = false;
    draft.getQuestions.count = 0;
    draft.getQuestions.data = [];
  });
}

export function getQuestionsSuccessReducer(
  state: QuestionState,
  data: GetQuestionsResponse
): QuestionState {
  return produce(state, (draft: QuestionState) => {
    draft.getQuestions.isLoading = false;
    draft.getQuestions.isLoaded = true;
    draft.getQuestions.isValid = true;
    draft.getQuestions.count = data.data.count;
    draft.getQuestions.data = transformQuestions(data.data.questions);
  });
}

export function getQuestionsFailureReducer(
  state: QuestionState
): QuestionState {
  return produce(state, (draft: QuestionState) => {
    draft.getQuestions.isLoading = false;
    draft.getQuestions.isLoaded = true;
    draft.getQuestions.isValid = false;
  });
}

export function clearGetQuestionsReducer(state: QuestionState): QuestionState {
  return produce(state, (draft: QuestionState) => {
    draft.getQuestions = getQuestionsInitialState;
  });
}

export function updateGetQuestionsQueryReducer(
  state: QuestionState,
  query: GetQuestionsQuery
): QuestionState {
  return produce(state, (draft: QuestionState) => {
    draft.getQuestions.isLoading = true;
    draft.getQuestions.query = query;
  });
}

export const getQuestionsReducers = {
  [`${getQuestions}`]: getQuestionsReducer,
  [`${getQuestionsSuccess}`]: getQuestionsSuccessReducer,
  [`${getQuestionsFailure}`]: getQuestionsFailureReducer,
  [`${clearGetQuestions}`]: clearGetQuestionsReducer,
  [`${updateGetQuestionsQuery}`]: updateGetQuestionsQueryReducer,
};
