import { InfoOutlined } from '@mui/icons-material';
import { Popover } from 'elmo-elements';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import round from 'lodash/round';
import isNil from 'lodash/isNil';
import React from 'react';

import { reportFilters } from '../../page/OverviewReport/state/state';
import history, { getCurrentPage } from '../history';
import { SelectedFiltersState } from 'type';
import translations from './translations';
import { t } from '../translation';
import { getRoute } from '../util';
import auth from '../access';

export const compliedRoute = (path: string, surveyId: number) => {
  return getRoute(path, { params: { surveyId } });
};

/**
 * Get the current tab
 */
export const getCurrentTab = (
  surveyId: number
):
  | 'questionSummary'
  | 'individualResponses'
  | 'details'
  | 'collectors'
  | 'filters'
  | 'notifications'
  | 'benchmark'
  | null => {
  let currentPage = getCurrentPage();

  switch (currentPage) {
    case compliedRoute(
      '/surveys/:surveyId/reports/question-summaries',
      surveyId
    ):
      return 'questionSummary';
    case compliedRoute('/surveys/:surveyId/details', surveyId):
      return 'details';
    case compliedRoute(
      '/surveys/:surveyId/reports/individual-responses',
      surveyId
    ):
      return 'individualResponses';
    case compliedRoute('/surveys/:surveyId/collectors', surveyId):
      return 'collectors';
    case compliedRoute('/surveys/:surveyId/filters', surveyId):
      return 'filters';
    case compliedRoute('/surveys/:surveyId/notifications', surveyId):
      return 'notifications';
    case compliedRoute('/surveys/:surveyId/reports/benchmark', surveyId):
      return 'benchmark';
    default:
      return null;
  }
};

/**
 * Get tabs to show in header, must be function for isCurrentPage to run correctly when tabs are changed
 */
export const getReportTabs = (surveyId: number) => {
  const currentTab = getCurrentTab(surveyId);

  if (auth.isOnlyReportViewer()) {
    return [
      {
        active: currentTab === 'questionSummary',
        label: t(translations.reports.tabs.questionSummary),
        onClick: () => {
          history.push(
            compliedRoute(
              '/surveys/:surveyId/reports/question-summaries',
              surveyId
            )
          );
        },
      },
      {
        active: currentTab === 'individualResponses',
        label: t(translations.reports.tabs.individualResponses),
        onClick: () => {
          history.push(
            compliedRoute(
              '/surveys/:surveyId/reports/individual-responses',
              surveyId
            )
          );
        },
      },
    ];
  }

  return [
    {
      active: currentTab === 'details',
      label: t(translations.reports.tabs.details),
      onClick: () => {
        history.push(compliedRoute('/surveys/:surveyId/details', surveyId));
      },
    },
    {
      active: currentTab === 'filters',
      label: t(translations.reports.tabs.filters),
      onClick: () => {
        history.push(compliedRoute('/surveys/:surveyId/filters', surveyId));
      },
    },
    {
      active: currentTab === 'collectors',
      label: t(translations.reports.tabs.collectors),
      onClick: () => {
        history.push(compliedRoute('/surveys/:surveyId/collectors', surveyId));
      },
    },
    {
      active: currentTab === 'notifications',
      label: t(translations.reports.tabs.notifications),
      onClick: () => {
        history.push(
          compliedRoute('/surveys/:surveyId/notifications', surveyId)
        );
      },
    },
    {
      active: currentTab === 'questionSummary',
      label: t(translations.reports.tabs.questionSummary),
      onClick: () => {
        history.push(
          compliedRoute(
            '/surveys/:surveyId/reports/question-summaries',
            surveyId
          )
        );
      },
    },
    {
      active: currentTab === 'benchmark',
      label: t(translations.reports.tabs.benchmark),
      onClick: () => {
        history.push(
          compliedRoute('/surveys/:surveyId/reports/benchmark', surveyId)
        );
      },
    },
    {
      active: currentTab === 'individualResponses',
      label: t(translations.reports.tabs.individualResponses),
      onClick: () => {
        history.push(
          compliedRoute(
            '/surveys/:surveyId/reports/individual-responses',
            surveyId
          )
        );
      },
    },
  ];
};

export const collectorFilterStateToQueryString = (
  collectorFilterState: any[] = []
): string => {
  if (collectorFilterState.length === 0) {
    return '';
  }

  return collectorFilterState
    .map((collectorFilter) => collectorFilter.data.id)
    .join();
};

export const collectorFilterStateToIdsArray = (
  collectorFilterState: any[] = []
): number[] => {
  if (collectorFilterState.length === 0) {
    return [];
  }

  return collectorFilterState.map((collectorFilter) => collectorFilter.data.id);
};

export const associatedFilterStateToQueryString = (
  associatedFilterState: any = {}
): string => {
  let outputObject: any = {};

  Object.keys(associatedFilterState).forEach((filterId: string): any => {
    outputObject[Number(filterId)] = associatedFilterState[filterId].map(
      (filterValue: any) => {
        return filterValue.data.id;
      }
    );
  });

  return Object.keys(outputObject).length === 0
    ? '[]'
    : JSON.stringify(outputObject);
};

export const associatedFilterStateToSavedFiltersObject = (
  associatedFilterState: any = {}
): string => {
  let outputObject: any = {};

  Object.keys(associatedFilterState).forEach((filterId: string): any => {
    outputObject[Number(filterId)] = associatedFilterState[filterId].map(
      (filterValue: any) => {
        return filterValue.data.id;
      }
    );
  });

  return Object.keys(outputObject).length === 0 ? {} : outputObject;
};

export const countSelectedFilters = (
  selectedFilters: SelectedFiltersState
): number => {
  let count = 0;

  for (const [name, value] of Object.entries(selectedFilters)) {
    switch (name) {
      case 'collectors':
        if (selectedFilters.collectors.length > 0) {
          count++;
        }
        break;
      case 'associatedFilters':
        count += value ? Object.keys(value).length : 0;
        break;
      default:
        if (
          !isEmpty(value) &&
          !isEqual(value, reportFilters[name as keyof SelectedFiltersState])
        ) {
          count++;
        }
    }
  }

  return count;
};

/**
 * Capitalise first letter of a word
 * @param text
 */
export const capitaliseFirstLetter = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const calculateChoicePercentage = (
  choice: any,
  totalResponsesCount: number
) => {
  if (choice.count === 0) {
    return 0;
  }

  return round((choice.count / totalResponsesCount) * 100, 2);
};

export const getBackButton = () => {
  return {
    onClick: () => {
      if (auth.isOnlyReportViewer()) {
        history.push('/reports/survey-overview/active');
      } else {
        history.push('/surveys/active');
      }
    },
  };
};

export const displayValueOrNa = (
  value: number | undefined | null,
  showAsPercentage: boolean = false
) => {
  const extra = showAsPercentage ? '%' : '';

  return isNil(value) ? 'N/A' : `${value}${extra}`;
};

export const displayRegionBenchmarkScore = function (
  thresholdsMet: boolean,
  score: number | undefined | null
) {
  if (!thresholdsMet) {
    return (
      <Popover>
        <Popover.Target>
          {t(translations.reports.benchmarkScore.notMet)} <InfoOutlined />
        </Popover.Target>
        <Popover.Content>
          <div>{t(translations.reports.benchmarkScore.notMetToolTip)}</div>
        </Popover.Content>
      </Popover>
    );
  }

  return <>{displayValueOrNa(score, true)}</>;
};
