import { RouteObject } from 'react-router-dom';
import React from 'react';

import QuestionCategoryList from './pages/QuestionCategoryList';

export const QUESTION_CATEGORIES_PATH = '/new-question-categories';

const routes: RouteObject = {
  path: QUESTION_CATEGORIES_PATH,
  children: [
    {
      path: QUESTION_CATEGORIES_PATH,
      element: <QuestionCategoryList />,
    },
  ],
};

export default routes;
