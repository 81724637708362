export enum AnalyticsEventNames {
  // Associated filter
  SURVEY_ASSOCIATED_FILTER_ADDED = 'Survey Associated Filter Added',
  SURVEY_ASSOCIATED_FILTER_CREATED = 'Survey Associated Filter Created',
  // Benchmark
  SURVEY_BENCHMARK_REPORT_VIEWED = 'Survey Benchmark Report Viewed',
  // Bulk URL collector
  SURVEY_BULK_URL_COLLECTOR_GENERATED = 'Survey Bulk URL Collector Generated',
  // Overview report
  SURVEY_REPORT_EXPORTED = 'Survey Report Exported',
  SURVEY_REPORT_HTML_QUESTION_TOGGLED = 'Survey Report Html Question Toggled',
  SURVEY_REPORT_TEXT_QUESTION_RESPONSES_VIEWED = 'Survey Report Text Question Responses viewed',
  SURVEY_REPORT_FILTER_BUTTON_CLICKED = 'Survey Report Filter Button Clicked',
  SURVEY_REPORT_FILTER_APPLY_BUTTON_CLICKED = 'Survey Report Filter Apply Button Clicked',
  SURVEY_REPORT_BREAKDOWN_BUTTON_CLICKED = 'Survey Report Break Down Button Clicked',
  SURVEY_REPORT_LIST_PAGE_VIEWED = 'Survey Report List Page Viewed',
  SURVEY_REPORT_PAGE_VIEWED = 'Survey Report Page Viewed',
  SURVEY_REPORT_EXPORT_PDF_CLICKED = 'Survey Overview Report Export PDF Clicked',
  SURVEY_REPORT_CHARTS_BUTTON_CLICKED = 'Survey Overview Report Charts Button Clicked',
  SURVEY_REPORT_CHARTS_APPLIED = 'Survey Overview Report Charts Applied',
  // Individual report
  SURVEY_INDIVIDUAL_RESPONSES_VIEWED = 'Survey Individual Responses Viewed',
  // Collectors Tab
  SURVEY_COLLECTOR_TAB_VIEWED = 'Survey Collector Tab Viewed',
  SURVEY_COLLECTOR_TAB_ADDED = 'Survey Collector Added new',
  SURVEY_COLLECTOR_TAB_CLOSED = 'Survey Collector Closed new',
  SURVEY_COLLECTOR_DOWNLOAD_QR_CODE_BUTTON_CLICKED = 'Survey Collector Download QR Code Button Clicked',
  // Email Collector
  SURVEY_EMAIL_COLLECTOR_MESSAGES_TAB_VIEWED = 'Survey Email Message Tab Viewed',
  SURVEY_EMAIL_COLLECTOR_MANAGE_EMAIL_BUTTON_CLICKED = 'Survey Manage Invites Button Clicked',
  SURVEY_EMAIL_COLLECTOR_INVITE_PARTICIPANTS_BUTTON_CLICKED = 'Survey Invite Participants Button Clicked',
  SURVEY_EMAIL_COLLECTOR_EDIT_MESSAGE_BUTTON_CLICKED = 'Survey Edit Message Button Clicked',
  SURVEY_EMAIL_COLLECTOR_ADD_MESSAGE_BUTTON_CLICKED = 'Survey Add Message Button Clicked',
  SURVEY_EMAIL_COLLECTOR_SAVE_MESSAGE_BUTTON_CLICKED = 'Survey Save Message Button Clicked',
  SURVEY_EMAIL_COLLECTOR_CLEAR_DRAFT_BUTTON_CLICKED = 'Survey Clear Draft Button Clicked',
  SURVEY_EMAIL_COLLECTOR_INVITE_SENT_BY_SEND_NOW_BUTTON = 'Survey Invite Sent by Send Now Button',
  SURVEY_EMAIL_COLLECTOR_INVITE_SCHEDULED_BY_SEND_LATER_BUTTON = 'Survey Invite Scheduled by Send Later Button',
  SURVEY_EMAIL_COLLECTOR_INVITE_EXPORT_BUTTON_CLICKED = 'Survey Invite Export Button Clicked',
  SURVEY_EMAIL_COLLECTOR_RECIPIENTS_EXPORT_BUTTON_CLICKED = 'Survey Recipients Export Button Clicked',
  SURVEY_EMAIL_COLLECTOR_SEND_LATER_SAVE_BUTTON_CLICKED = 'Survey Send Later And Save Button Clicked',
  SURVEY_EMAIL_COLLECTOR_VIEW_RECIPIENTS = 'Survey View Recipients',
  SURVEY_EMAIL_COLLECTOR_EDIT_SCHEDULE_BUTTON_CLICKED = 'Survey Edit Schedule Button Clicked',
  SURVEY_EMAIL_COLLECTOR_MANAGE_INVITE_BUTTON_CLICKED = 'Survey Manage Invite Button Clicked',
  SURVEY_EMAIL_COLLECTOR_DELETE_INVITE_BUTTON_CLICKED = 'Survey Delete Invite Button Clicked',
  SURVEY_EMAIL_COLLECTOR_ADD_REMINDER_BUTTON_CLICKED = 'Survey Add Reminder Button Clicked',
  SURVEY_EMAIL_COLLECTOR_ADD_REMINDER_AFTER_SAVE = 'Survey Add Reminder Button Clicked After Invite Saved',
  SURVEY_EMAIL_COLLECTOR_RESCHEDULE_REMINDER = 'Survey Reschedule Reminder Button Clicked',
  SURVEY_EMAIL_COLLECTOR_DELETE_REMINDER_BUTTON_CLICKED = 'Survey Delete Reminder Button Clicked',
  SURVEY_EMAIL_COLLECTOR_DELETE_NEW_REMINDER_BUTTON_CLICKED = 'Survey Delete New Reminder Button Clicked',
  SURVEY_EMAIL_COLLECTOR_SEND_SCHEDULE_BUTTON_CLICKED = 'Survey Send Schedule Button Clicked',
  SURVEY_EMAIL_COLLECTOR_RESCHEDULE_BUTTON_CLICKED = 'Survey Reschedule Button Clicked',
  SURVEY_EMAIL_COLLECTOR_SAVE_INVITE_BUTTON_CLICKED = 'Survey Save Invite Button Clicked',
  SURVEY_EMAIL_COLLECTOR_RECIPIENT_DISABLED = 'Survey Email Recipient Disabled',
  SURVEY_EMAIL_COLLECTOR_RECIPIENT_ENABLED = 'Survey Email Recipient Enabled',
  // Details Tab
  SURVEY_DETAILS_TAB_VIEWED = 'Survey Details Tab Viewed',
  SURVEY_DETAILS_TAB_MANAGE_COLLECTORS_CLICKED = 'Survey Details Manage collectors button clicked',
  SURVEY_DETAILS_PREVIEW_LINK_COPIED = 'Survey Details Preview Link Copied',
  // NotificationsList Tab
  SURVEY_NOTIFICATION_TAB_VIEWED = 'Survey Notifications Tab Viewed',
  SURVEY_NOTIFICATION_CREATE_BUTTON_CLICKED = 'Survey Notifications Create Button Clicked',
  SURVEY_NOTIFICATION_CREATED = 'Survey Notifications Created',
  SURVEY_NOTIFICATION_EDITED = 'Survey Notifications Edited',
  SURVEY_NOTIFICATION_EDIT_ACTION_CLICKED = 'Survey Notifications Edit Action Clicked',
  SURVEY_NOTIFICATION_DISABLED = 'Survey Notifications Disabled',
  SURVEY_NOTIFICATION_HISTORY_PAGE_VIEWED = 'Survey Notification History Page Viewed',
  // Shared report
  SURVEY_SHARED_REPORT_BUTTON_CLICKED = 'Share Report button clicked',
  SURVEY_SHARED_REPORT_BULK_LINKS_DOWNLOADED = 'Survey Shared Report Bulk Links Downloaded',
  SURVEY_SHARED_REPORT_BULK_LINKS_GENERATED = 'Survey Shared Report Bulk Links Generated',
  SURVEY_SHARED_REPORT_INDIVIDUAL_LINK_GENERATED = 'Survey Shared Report Individual Link Generated',
  SURVEY_SHARED_REPORT_INDIVIDUAL_LINK_COPIED = 'Survey Shared Report Individual Link Copied',
  SURVEY_SHARED_REPORT_INDIVIDUAL_LINK_CLOSED = 'Survey Shared Report Individual Link Closed',
  SURVEY_SHARED_REPORT_EXPORT_CSV_CLICKED = 'Survey Shared Report Export CSV Clicked',
  SURVEY_SHARED_REPORT_EXPORT_RAW_CSV = 'Survey Shared Report Export Raw CSV',
  SURVEY_SHARED_REPORT_CHARTS_BUTTON_CLICKED = 'Survey Shared Charts Button Clicked',
  // My survey
  SURVEY_LIST_PAGE_VIEWED = 'Survey List Page Viewed',
  SURVEY_MOVED_TO_DRAFT = 'Survey Moved to Draft',
  SURVEY_LIST_CLICK_ACTION_PREVIEW_SURVEY = 'Survey List Click Action Preview Survey',
  SURVEY_LIST_CLICK_ACTION_SURVEY_ACCESS = 'Survey List Click Action Survey Access',
  SURVEY_LIST_SURVEY_ACCESS_UPDATED = 'Survey List Survey Access Updated',
  // My survey template selection
  SURVEY_TEMPLATE_SELECTION_VIEW_ALL_TEMPLATES_CLICKED = 'Survey View all templates clicked',
  SURVEY_TEMPLATE_SELECTION_CREATE_CUSTOM_SURVEY_CLICKED = 'Survey Created custom survey',
  SURVEY_TEMPLATE_SELECTION_TEMPLATE_SELECT_CLICKED = 'Survey Template Prompt Select Clicked',
  SURVEY_TEMPLATE_SELECTION_PREVIEW_BUTTON_CLICKED = 'Survey Template Selection Preview Button Clicked',
  // Help centre
  SURVEY_HELP_CENTRE_FILE_DOWNLOADED = 'Survey Help Centre Document downloaded',
  // Survey Preview
  SURVEY_PREVIEW_MODAL_LINK_COPIED = 'Survey Preview Modal Link Copied',
  SURVEY_PREVIEW_VALIDATION_DISABLED = 'Survey Preview Validation Disabled',
  SURVEY_PREVIEW_ONE_PAGE_ENABLED = 'Survey Preview One Page Enabled',
  SURVEY_PREVIEW_LINK_VALIDATION_DISABLED = 'Survey Preview Link Validation Disabled',
  SURVEY_PREVIEW_LINK_ONE_PAGE_ENABLED = 'Survey Preview Link One Page Enabled',
  // Response events
  SURVEY_RESPONSE_COMPLETED = 'Survey Response Completed',
  SURVEY_RESPONSE_STARTED = 'Survey Response Started',
  // Theme events
  SURVEY_THEME_CREATED = 'Survey Theme Created',
  // Other
  SURVEY_PAGE_VIEWED = 'Survey Page Viewed',
}
