import { APIQuestion, ErrorResponse, Question } from 'type';
import { Query } from 'lib/query/type';

export interface GetQuestionsState {
  isLoaded: boolean;
  isLoading: boolean;
  isValid: boolean;
  count: number;
  data: Question[];
  errors?: ErrorResponse;
  query: GetQuestionsQuery;
}

export interface GetQuestionsResponse {
  data: {
    count: number;
    questions: APIQuestion[];
  };
}

export enum QuestionSearchField {
  CATEGORY_TITLE = 'category-title',
  QUESTION = 'question',
}

export type GetQuestionsQuery = Query & { searchField?: QuestionSearchField };
