import { createReducer } from 'redux-act';

import { getQuestionsReducers } from './getQuestions/reducers';
import { questionInitialState } from './state';

export default createReducer(
  {
    ...getQuestionsReducers,
  },
  questionInitialState
);
