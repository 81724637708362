import {
  Button,
  Card,
  FileUpload,
  Paragraph,
  CancelIcon,
  Loader,
} from 'elmo-elements';
import { UploadedFile } from 'elmo-elements/FileUpload';
import React, { ReactElement } from 'react';

interface UploadCardProps {
  title: string;
  description: string;
  dropAreaText?: string;
  acceptedFiles?: string[];
  onDropAccepted: (files: UploadedFile) => void;
  onDropRejected: (files: UploadedFile) => void;
  handleFileDelete: () => void;
  isUploading: boolean;
  uploadedFileName: string | null;
}

const UploadCard = ({
  title,
  description,
  dropAreaText = 'Choose a csv file or drag it here',
  acceptedFiles = ['.csv'],
  onDropAccepted,
  onDropRejected,
  handleFileDelete,
  isUploading,
  uploadedFileName,
}: UploadCardProps): ReactElement => {
  return (
    <Card className={'import-csv-template-card'} heading={title}>
      <Paragraph>{description}</Paragraph>
      <br />
      {uploadedFileName && !isUploading && (
        <div className={`elmo-uploaded-file`}>
          <span>{uploadedFileName}</span>
          <Button
            icon={<CancelIcon />}
            onClick={handleFileDelete}
            id={'remove-file'}
          />
        </div>
      )}

      {isUploading && <Loader type={'spinner'} />}

      {!uploadedFileName && !isUploading && (
        <FileUpload
          name={'import-csv-template-file-upload'}
          acceptedFiles={acceptedFiles}
          dropareaText={dropAreaText}
          displayFiles={false}
          isMulti={false}
          onDropAccepted={(files: UploadedFile[]) => onDropAccepted(files[0])}
          onDropRejected={(files: UploadedFile[]) => onDropRejected(files[0])}
          key={1}
        />
      )}
    </Card>
  );
};

export default UploadCard;
