// import { loadTranslations } from '../translation';
// import { loadAssets } from '../assets';
// import { ErrorAppInitUndefinedError } from '../error';

/**
 * App setup and initialisations
 *
 *
 * <Dev.Note>
 *  Any async/sync activities that need to be done first in order to load the app can be impelemnted here.
 *  As you can see we dont implement the logic related to each action in this file so we keep this setup as
 *  minimal and abstract as posible. In otherword the job of this `setup` function is to orchestrate some
 *  individual set up activities.
 * </Dev.Note>
 */
function setup(): Promise<any> {
  return Promise.resolve(true);
  // const promises: Promise<any>[] = [loadTranslations(), loadAssets()];
  //
  // return Promise.all(promises)
  //   .then(([user, config]: any) => ({ user, config }))
  //   .catch((error: any) => {
  //     // <Dev.Note>
  //     // The following condition should happen very rarely. It means you have an exception but there
  //     // is no valid error for that. For example you return a Promise.reject() isnide a function.
  //     // </Dev.Note>
  //     if (!error) {
  //       error = new ErrorAppInitUndefinedError();
  //     }
  //     return Promise.reject(error);
  //   });
}

export default setup;
