import { ActionCreator, createAction, EmptyActionCreator } from 'redux-act';

import { GetThemesResponse } from './type';
import { Query } from 'lib/query/type';

export const getThemes: EmptyActionCreator = createAction('GET_THEMES');

export const getThemesSuccess: ActionCreator<GetThemesResponse> =
  createAction('GET_THEMES_SUCCESS');

export const getThemesFailure: EmptyActionCreator =
  createAction('GET_THEMES_FAILURE');

export const clearGetThemes: EmptyActionCreator =
  createAction('CLEAR_GET_THEMES');

export const updateGetThemesQuery: ActionCreator<Query> = createAction(
  'UPDATE_GET_THEMES_QUERY'
);
