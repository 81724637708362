import { UploadedFile } from 'elmo-elements/FileUpload';
import { useDispatch, useSelector } from 'react-redux';
import PeopleIcon from '@mui/icons-material/People';
import React, { useEffect } from 'react';

import { uploadInviteRecipientsCSVInitialState } from 'store/modules/invites/uploadInviteRecipientsCSV/state';
import {
  clearUploadInviteRecipientsCSV,
  uploadInviteRecipientsCSV,
} from 'store/actions';
import { Recipients } from 'store/modules/invites/manageInvite/type';
import ImportCSVTemplate from 'sharedComponents/ImportCSVTemplate';
import NotificationPopUp from 'sharedComponents/NotificationPopUp';
import BaseModal from 'element/Modal/components/baseModal';
import translations from './translations';
import { RootState } from 'store/type';
import { t } from 'lib/translation';

interface CSVImportProps {
  recipientsState: Recipients;
  updateRecipientsStateByField: (field: string, data: any) => void;
  updateRecipientsState: (data: any) => void;
  saveRecipients: () => void;
  previousStep: () => void;
  closeModal: () => void;
  isFlow: boolean;
  collectorId: number;
}

const CSVImport = ({
  closeModal,
  previousStep,
  recipientsState,
  updateRecipientsStateByField,
  saveRecipients,
  isFlow,
  collectorId,
}: CSVImportProps) => {
  const dispatch = useDispatch();

  const uploadFileState = useSelector(
    (state: RootState) => state.emailInvites.uploadInviteRecipientsCSV
  );

  const downloadTemplate = () => {
    window.location.assign(
      `/api/collectors/${collectorId}/email-recipient-lists/import-csv/download`
    );
  };

  const uploadFile = (uploadedFile: UploadedFile) => {
    dispatch(
      uploadInviteRecipientsCSV({
        collectorId: collectorId,
        file: uploadedFile,
      })
    );
  };

  const removeFile = () => {
    dispatch(clearUploadInviteRecipientsCSV());
    updateRecipientsStateByField(
      'csvUpload',
      uploadInviteRecipientsCSVInitialState.data
    );
  };

  const clearUploadAttempt = () => {
    dispatch(clearUploadInviteRecipientsCSV());
  };

  useEffect(() => {
    if (uploadFileState.isUpdated && uploadFileState.isValid) {
      updateRecipientsStateByField('csvUpload', uploadFileState.data);
    }
  }, [uploadFileState]);

  useEffect(() => {
    return () => {
      dispatch(clearUploadInviteRecipientsCSV());
    };
  }, []);

  return (
    <>
      <BaseModal
        id={'manage-invite-csv-import'}
        closeModal={closeModal}
        title={
          <>
            <PeopleIcon className={'header-icon'} />
            {t(translations.title)}
          </>
        }
        modalType={'x-large'}
        primaryButtonIsDisabled={!recipientsState.csvUpload.displayName}
        primaryButtonClick={saveRecipients}
        primaryButtonText={
          isFlow
            ? t(translations.primaryButton.saveAndContinue)
            : t(translations.primaryButton.saveAndClose)
        }
        secondaryButtonText={t(translations.backButton)}
        secondaryButtonClick={previousStep}
        buttonsType={'separate'}
      >
        <>
          <ImportCSVTemplate
            downloadTitle={t(translations.downloadStep.title)}
            downloadDescription={t(translations.downloadStep.description)}
            downloadAction={downloadTemplate}
            uploadTitle={t(translations.uploadStep.title)}
            uploadDescription={t(translations.uploadStep.description)}
            handleFileDelete={removeFile}
            onDropAccepted={uploadFile}
            onDropRejected={() => true}
            isUploading={uploadFileState.isUpdating}
            uploadedFileName={recipientsState.csvUpload.displayName}
          />
          <NotificationPopUp
            errorMessage={uploadFileState.errors}
            onCloseCallBack={clearUploadAttempt}
          />
        </>
      </BaseModal>
    </>
  );
};

export default CSVImport;
