import { createReducer } from 'redux-act';

import { getFeaturedTemplatesReducers } from './getFeaturedTemplates/reducers';
import { updateSurveyTemplateReducers } from './updateSurveyTemplate/reducers';
import { deleteSurveyTemplateReducers } from './deleteSurveyTemplate/reducers';
import { getSurveyTemplatesReducers } from './getSurveyTemplates/reducers';
import { surveyTemplateInitialState } from './state';

export default createReducer(
  {
    ...deleteSurveyTemplateReducers,
    ...getFeaturedTemplatesReducers,
    ...getSurveyTemplatesReducers,
    ...updateSurveyTemplateReducers,
  },
  surveyTemplateInitialState
);
