import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  getThemesFailure,
  getThemesSuccess,
  updateGetThemesQuery,
} from './actions';
import { Query } from 'lib/stateTemplates';
import { getQuery } from 'lib/query';
import { getThemesApi } from './api';

export const getThemesSaga = function* (): SagaIterator {
  try {
    const queryData = getQuery(Query);

    yield put(updateGetThemesQuery(queryData));

    const data = yield call(getThemesApi(queryData));

    yield put(getThemesSuccess(data));
  } catch (errors) {
    yield put(getThemesFailure());
  }
};
